import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{attrs:{"fixed":"","temporary":"","app":"","clipped":""},model:{value:(_vm.drawerValue),callback:function ($$v) {_vm.drawerValue=$$v},expression:"drawerValue"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_c(VImg,{staticClass:"shrink mr-2",attrs:{"contain":"","height":"40","alt":"Sinergia Médica Logo","transition":"scale-transition","src":require('@/assets/logo_full.png')}})],1)],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":"","nav":""}},_vm._l((_vm.items),function(item,i){return (item.visible)?_c(VListItem,{key:i,attrs:{"to":{ name: item.link },"link":""}},[_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$store.getters.getLangEn ? item.title_en : item.title)+" ")])],1)],1):_vm._e()}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }