<template>
  <v-navigation-drawer v-model="drawerValue" fixed temporary app clipped>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <v-img
            contain
            height="40"
            alt="Sinergia Médica Logo"
            class="shrink mr-2"
            transition="scale-transition"
            :src="require('@/assets/logo_full.png')"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list dense nav>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :to="{ name: item.link }"
        v-if="item.visible"
        link
      >
        <v-list-item-icon class="mr-3">
          <v-icon v-text="item.icon" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $store.getters.getLangEn ? item.title_en : item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["drawer"],
  data() {
    return {
      login: this.$store.getters.getLogin,
      items: [],
    };
  },
  computed: {
    drawerValue: {
      get: function () {
        return this.drawer;
      },
      set: function (v) {
        this.$emit("update:drawer", v);
      },
    },
  },
  mounted() {
    this.items = [
      {
        title: "Inicio",
        title_en: "Home",
        icon: "mdi-home",
        link: "home",
        visible: true,
      },
      {
        title: "Consultas | Solicitud de cobro",
        title_en: "Consultations | Collection request",
        icon: "mdi-file-table-box-multiple",
        link: "doctor.services",
        visible: this.login.role_id == 5,
      },
      {
        title: "Preguntas o comentarios",
        title_en: "Questions or comments",
        icon: "mdi-file-table-box-multiple",
        link: "doctor.comments",
        visible: this.login.role_id == 5,
      },
      {
        title: "Servicios",
        title_en: "Services",
        icon: "mdi-file-table-box-multiple",
        link: "insured.services",
        visible: this.login.role_id == 7,
      },
      {
        title: "Ingresos",
        title_en: "Admissions",
        icon: "mdi-file-table-box-multiple",
        link: "insured.rhas",
        visible: this.login.role_id == 7,
      },
      {
        title: "Servicios",
        title_en: "Services",
        icon: "mdi-file-table-box-multiple",
        link: "provider.services",
        visible: this.login.role_id == 4,
      },
      {
        title: "Servicios",
        title_en: "Services",
        icon: "mdi-file-table-box-multiple",
        link: "pharmacy.services",
        visible: this.login.role_id == 13,
      },
      {
        title: "Servicios",
        title_en: "Services",
        icon: "mdi-file-table-box-multiple",
        link: "president.services",
        visible: this.login.role_id == 16,
      },
      {
        title: "Mi perfil",
        title_en: "Profile",
        icon: "mdi-shield-account-outline",
        link: "user.profile",
        visible: true,
      },
    ];
  },
};
</script>

<style>
</style>