// export const URL = "http://127.0.0.1:8000";
// export const URL = "https://sinergiamedica.icu/server";
export const URL = "https://sinergiamedica.club/server";
export const URL_API = URL + "/api";
export const URL_PDF = URL + "/pdf";
export const URL_DOCUMENTS = URL + "/documents/download";

export const headers = (token) => {
  let headers = {
    //'Content-Type': 'application/json',
    "Content-Type": "multipart/form-data",
    "X-Requested-With": "XMLHttpRequest",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return { headers };
};

export const msgAlert = (type, msg) => {
  return {
    showConfirmButton: false,
    icon: type,
    text: msg,
    timer: 4000,
  };
};

export const msgConfirm = (msg) => {
  return {
    showCancelButton: true,
    cancelButtonText: "No",
    confirmButtonText: "Sí",
    icon: "warning",
    text: msg,
  };
};

export const msgConfirmEn = (lang_en, msg_en, msg_es) => {
  return {
    showCancelButton: true,
    cancelButtonText: "No",
    confirmButtonText: lang_en ? "Yes" : "Sí",
    icon: "warning",
    html: lang_en ? msg_en : msg_es,
  };
};

export const msgConfirmCancel = (msg) => {
  return {
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonText: "Sí",
    showDenyButton: true,
    denyButtonText: `No`,
    icon: "warning",
    text: msg,
  };
};

export const dateTimeNow = () => {
  let dt = new Date();

  return `${dt.getFullYear().toString().padStart(4, "0")}-${(dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")} ${dt
    .getHours()
    .toString()
    .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
};

export const dateTimeToFile = () => {
  let dt = new Date();

  return `${dt.getFullYear().toString().padStart(4, "0")}${(dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}${dt.getDate().toString().padStart(2, "0")}${dt
    .getHours()
    .toString()
    .padStart(2, "0")}${dt.getMinutes().toString().padStart(2, "0")}${dt
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
};

export const toFormData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      Array.isArray(data[key])
    ) {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }

    if (key == "documents" || key == "rs_service_bill_files") {
      for (var i = 0; i < data[key].length; i++) {
        for (var document_key in data[key][i]) {
          if (document_key === "file")
            formData.append("document_file_" + i, data[key][i][document_key]);
        }
      }

      //formData.append('total_documents', data[key].length)
    }
  });

  return formData;
};

export const serviceToFormData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (
      typeof data[key] === "object" &&
      data[key] !== null &&
      Array.isArray(data[key])
    ) {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }

    if (
      key == "documents" ||
      key == "rs_documents" ||
      key == "rs_service_bill_files"
    ) {
      for (var i = 0; i < data[key].length; i++) {
        for (var document_key in data[key][i]) {
          if (document_key === "file")
            formData.append("document_file_" + i, data[key][i][document_key]);
        }
      }
    }

    if (key == "rs_refound_bills") {
      for (var i = 0; i < data[key].length; i++) {
        for (var document_key in data[key][i]) {
          if (document_key === "pdf") {
            formData.append(
              "document_rs_refound_bill_pdf_" + i,
              data[key][i][document_key]
            );
          }

          if (document_key === "xml") {
            formData.append(
              "document_rs_refound_bill_xml_" + i,
              data[key][i][document_key]
            );
          }
        }
      }
    }
  });

  return formData;
};

export const fileDownload = (base64, ext, name) => {
  const binary_string = window.atob(base64.replace(/\s/g, ""));
  const len = binary_string.length;
  let bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  const blob = new Blob([bytes.buffer], {
    type: "application/" + ext,
  });
  let downloadLink = document.createElement("a");
  downloadLink.setAttribute("target", "_blank");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = name + "." + ext;
  downloadLink.click();
};

export const base64ToArrayBuffer = (base64) => {
  const binary_string = window.atob(base64.replace(/\s/g, ""));
  const len = binary_string.length;
  let bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  return bytes.buffer;
};

export const rules = () => {
  return {
    password: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length >= 8) || "Mínimo 8 caracteres.",
      (v) => (v && v.length <= 20) || "Máximo 20 caracteres.",
      (v) => /(?=.*[A-Z])/.test(v) || "Al menos una mayúscula.",
      (v) => /(?=.*[a-z])/.test(v) || "Al menos una minúscula.",
      (v) => /(?=.*\d)/.test(v) || "Al menos un número.",
      (v) =>
        /([!@$%*])/.test(v) || "Al menos un caractere especial (! @ $ % *).",
    ],
    email: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 50) || "Máximo 50 caracteres.",
      (v) => /.+@.+\..+/.test(v) || "Formato invalido.",
    ],
    emailNoRequired: [
      (v) => {
        if (v) return (v && v.length <= 50) || "Máximo 50 caracteres.";
        else return true;
      },
      (v) => {
        if (v) return /.+@.+\..+/.test(v) || "Formato invalido.";
        else return true;
      },
    ],
    required: [(v) => !!v || "Campo requerido."],
    fileRequired800kB: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.size < 819200) || "El tamaño máximo de carga es de 800kB",
    ],
    fileNotRequired800kB: [
      (v) => {
        if (v)
          return (
            (v && v.size < 819200) || "El tamaño máximo de carga es de 800kB"
          );
        else return true;
      },
    ],
    code_tax_sat: [
      (v) => !!v || "Campo requerido.",
      (v) => (v && v.length <= 13) || "Máximo 13 caracteres.",
      (v) => /^[A-Za-zñÑ&]{3,4}\d{6}\w{3}$/.test(v) || "Formato invalido.",
    ],
  };
};
