import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  //president
  {
    path: "/presidente/servicios",
    name: "president.services",
    component: () => import("../views/president/Services.vue"),
    meta: {
      title: "Servicios",
      title_en: "Services",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 16,
    },
  },
  //pharmacy
  {
    path: "/farmacia/servicios/documentacion/multiple",
    name: "pharmacy.services_multi_doc",
    component: () => import("../views/pharmacy/ServicesMultiDoc.vue"),
    meta: {
      title: "Servicios | Documentación multiple",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 13,
    },
  },
  {
    path: "/farmacia/servicios",
    name: "pharmacy.services",
    component: () => import("../views/provider/Services.vue"),
    meta: {
      title: "Servicios",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 13,
    },
  },
  {
    path: "/farmacia/servicios/documentacion/:rs_service_id",
    name: "pharmacy.services_documentation",
    component: () => import("../views/provider/ServicesDocumentation.vue"),
    meta: {
      title: "Servicios | Documentación",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 13,
    },
    props: true,
  },
  //provider
  {
    path: "/proveedor/servicios",
    name: "provider.services",
    component: () => import("../views/provider/Services.vue"),
    meta: {
      title: "Servicios",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 4,
    },
  },
  {
    path: "/proveedor/servicios/documentacion/:rs_service_id",
    name: "provider.services_documentation",
    component: () => import("../views/provider/ServicesDocumentation.vue"),
    meta: {
      title: "Servicios | Documentación",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 4,
    },
    props: true,
  },
  //insured
  {
    path: "/asegurado/servicios",
    name: "insured.services",
    component: () => import("../views/insured/Services.vue"),
    meta: {
      title: "Servicios",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 7,
    },
  },
  {
    path: "/asegurado/ingresos",
    name: "insured.rhas",
    component: () => import("../views/insured/Rhas.vue"),
    meta: {
      title: "Ingresos",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 7,
    },
  },
  //doctor
  {
    path: "/medico/preguntas_comentarios",
    name: "doctor.comments",
    component: () => import("../views/doctor/Comments.vue"),
    meta: {
      title: "Preguntas o comentarios",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 5,
    },
  },
  {
    path: "/medico/servicios",
    name: "doctor.services",
    component: () => import("../views/doctor/Services.vue"),
    meta: {
      title: "Consultas | Solicitud de cobro",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 5,
    },
  },
  {
    path: "/medico/servicios/documentacion/:rs_service_id",
    name: "doctor.services_documentation",
    component: () => import("../views/doctor/ServicesDocumentation.vue"),
    meta: {
      title: "Consultas médica | Documentación",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 5,
    },
    props: true,
  },
  {
    path: "/medico/edicion/informacion",
    name: "doctor.form_update",
    component: () => import("../views/doctor/FormUpdate.vue"),
    meta: {
      title: "Edición de información",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 5,
    },
  },
  //user_profile
  {
    path: "/perfil_usuario",
    name: "user.profile",
    component: () => import("../views/user/Profile.vue"),
    meta: {
      title: "Mi perfil",
      title_en: "Profile",
      requiresAuth: true,
      permission: true,
    },
  },
  //general
  {
    path: "/",
    name: "main",
    component: () => import("../views/general/Main.vue"),
    meta: { title: "SM INFO" },
  },
  {
    path: "/inicio_sesion",
    name: "login",
    component: () => import("../views/general/Login.vue"),
    meta: { title: "SM INFO" },
  },
  {
    path: "/inicio",
    name: "home",
    component: () => import("../views/general/Home.vue"),
    meta: { title: "SM INFO", requiresAuth: true, permission: true },
  },
  {
    path: "/acceso_denegado",
    name: "unauthorized",
    component: () => import("../views/general/Unauthorized.vue"),
    meta: { title: "Acceso denegado", requiresAuth: true, permission: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not_found",
    component: () => import("../views/general/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// function setPermission(interface_atr, permission) {
//   if (store.getters.getLogin.permissions) {
//     if (store.getters.getLogin.permissions[interface_atr]) {
//       return store.getters.getLogin.permissions[interface_atr][permission]
//     }
//     return false
//   }
//   return false
// }

router.beforeEach((to, from, next) => {
  let auth = store.getters.getLogin.auth;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.matched.some((record) => record.meta.permission)) {
      if (auth) {
        next();
      } else {
        next({ name: "login" });
      }
    } else {
      next({ name: "unauthorized" });
    }
  } else {
    if (auth) {
      next({ name: "home" });
    } else {
      next();
    }
  }

  document.title = to.meta.title;
});

export default router;
